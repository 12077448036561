import AuthService from '@/services/authService';

const state = {
  user: null,
};

const getters = {
  user: state => {
    return state.user;
  },
};

const actions = {
  changeUser: ({ commit }, user) => {
    commit('SET_USER', user);
    return user;
  },
  updateProfile: ({ commit, state }, credentials) => {
    return new Promise((resolve, reject) => {
      AuthService.updateUser(state.user.id, credentials)
        .then(response => {
          commit('SET_USER', response.data.user);
          resolve({
            data: {
              status: 'ok',
              response: response.data,
            },
          });
        })
        .catch(err => reject(err));
    });
  },
};

const mutations = {
  SET_USER: (state, user) => {
    state.user = user;
  },
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};
