import Vue from 'vue';
import Vuex from 'vuex';

import authentication from './modules/authentication';
import loading from './modules/loading';
import admin from './modules/admin';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    authentication,
    loading,
    admin,
  },
});
