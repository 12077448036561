import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import Swal from 'sweetalert2';

import '@/assets/css/main.css';

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

Vue.config.productionTip = false;

Vue.prototype.$AreYouSure = Swal.mixin({
  title:
    "<span class='text-md font-semibold text-gray-700 w-full text-left'>¿Estás seguro?</span>",
  customClass: {
    confirmButton:
      '!bg-green-600 !hover:bg-green-700 !text-gray-50 !font-bold focus:!border-0 !px-3 !py-1 !rounded-md !text-sm !font-medium',
    cancelButton:
      '!bg-red-100 !hover:bg-red-200 !text-red-900 !font-bold focus:!border-0 !px-3 !py-1 !rounded-md !text-sm !font-medium',
  },
  confirmButtonColor: '#13b981',
  confirmButtonText: 'Si',
  showCancelButton: true,
  cancelButtonText: 'No',
  icon: 'question',
});

Vue.prototype.$Success = Swal.mixin({
  position: 'top-end',
  icon: 'success',
  timer: 10000,
  toast: true,
  confirmButtonColor: '#13b981',
  timerProgressBar: true,
});

Vue.prototype.$Error = Swal.mixin({
  position: 'top-end',
  icon: 'error',
  timer: 10000,
  toast: true,
  confirmButtonColor: '#13b981',
  timerProgressBar: true,
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
