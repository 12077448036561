<template>
  <div>
    <nav class="w-full z-20 fixed bg-main border-b-0">
      <div class="max-w-full w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div class="relative flex items-center justify-between h-16">
          <!-- <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <button
              @click="
                () => {
                  nav_dropdown = !nav_dropdown;
                }
              "
              type="button"
              class="inline-flex items-center bg-green-500 justify-center p-2 rounded-md text-gray-50 hover:bg-green-600 focus:outline-none"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <svg
                class="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                class="hidden h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div> -->
          <div
            class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start"
          >
            <div
              @click="goRoot()"
              class="cursor-pointer flex-shrink-0 flex items-center"
            >
              <!-- <span
                class="text-xl -mb-1 hidden md:block font-extrabold text-gray-50 ml-2"
                >FUTURA</span
              > -->
              <img
                class="h-10"
                src="@/assets/logo-complete-transparent.png"
                alt=""
              />
            </div>
          </div>
          <div
            class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
          >
            <!-- <div
              class="hidden sm:block"
              :class="isauthenticated ? 'sm:mr-6' : ''"
            >
              <div v-if="isauthenticated" class="flex space-x-4">
                <router-link
                  tag="a"
                  to="/home"
                  class="bg-green-600 border border-green-900 hover:bg-green-700 text-gray-50 font-bold px-3 py-2 rounded-md text-sm font-medium"
                  aria-current="page"
                  >Inicio</router-link
                >
              </div>
              <div v-else class="flex space-x-4">
                <router-link
                  tag="a"
                  to="/login"
                  class="bg-green-600 border border-green-900 hover:bg-green-700 text-gray-50 font-bold px-3 py-2 rounded-md text-sm font-medium"
                  aria-current="page"
                  >Entrar</router-link
                >
                <a
                  @click="
                    () => {
                      $router.push({
                        path: '/login',
                        query: { mode: 'register' },
                      });
                    }
                  "
                  class="bg-green-600 cursor-pointer border border-green-900 hover:bg-green-700 text-gray-50 font-bold px-3 py-2 rounded-md text-sm font-medium"
                  aria-current="page"
                  >Registrarse</a
                >
              </div>
            </div> -->

            <div
              v-if="isauthenticated"
              v-click-outside="
                () => {
                  profile_dropdown = false;
                }
              "
              class="ml-3 relative"
            >
              <div>
                <button
                  type="button"
                  @click="
                    () => {
                      profile_dropdown = !profile_dropdown;
                    }
                  "
                  class="flex items-center text-sm rounded-full focus:outline-none"
                  id="user-menu-button"
                  aria-expanded="false"
                  aria-haspopup="true"
                >
                  <div
                    class="h-8 w-8 rounded-md bg-gray-400 flex justify-center items-center text-gray-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <svg
                    v-if="profile_dropdown"
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-2 ml-1 h-5 w-5 text-green-900"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-2 ml-1 h-5 w-5 text-green-900"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>

              <transition name="fade" mode="out-in">
                <div
                  v-if="profile_dropdown"
                  class="origin-top-right overflow-hidden absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-gray-50 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu-button"
                  tabindex="-1"
                >
                  <!-- Active: "bg-gray-100", Not Active: "" -->
                  <!-- <router-link
                    tag="a"
                    to="/profile"
                    class="flex flex-row justify-between items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-200"
                    role="menuitem"
                    tabindex="-1"
                    id="user-menu-item-0"
                  >
                    <span>Mi Perfil</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </router-link> -->
                  <!-- 
                  <a
                    href="#"
                    class="flex flex-row justify-between items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-200"
                    role="menuitem"
                    tabindex="-1"
                    id="user-menu-item-0"
                  >
                    <span>Ajustes</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a> -->
                  <a
                    href="#"
                    @click.prevent="logout_redirect()"
                    class="flex flex-row justify-between items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-200"
                    role="menuitem"
                    tabindex="-1"
                    id="user-menu-item-0"
                  >
                    <span>Salir</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>

      <!-- <transition name="fadeHeight" mode="out-in">
        <div v-if="nav_dropdown" class="sm:hidden" id="mobile-menu">
          <div v-if="isauthenticated" class="px-2 pt-2 pb-3 space-y-1">
            <router-link
              tag="a"
              to="/home"
              class="bg-green-500 text-gray-50 block px-3 py-2 rounded-md text-base font-medium"
              aria-current="page"
              >Inicio</router-link
            >
          </div>
          <div v-else class="px-2 pt-2 pb-3 space-y-1">
            <router-link
              tag="a"
              to="/login"
              href="#"
              class="bg-green-500 text-gray-50 block px-3 py-2 rounded-md text-base font-medium"
              aria-current="page"
              >Entrar</router-link
            >
            <a
              @click="
                () => {
                  $router.push({
                    path: '/login',
                    query: { mode: 'register' },
                  });
                }
              "
              class="bg-green-500 text-gray-50 block px-3 py-2 rounded-md text-base font-medium"
              aria-current="page"
              >Registrarse</a
            >
          </div>
        </div>
      </transition> -->
    </nav>
    <nav class="opacity-0 h-16 bg-green-500 border-b-4 border-green-600"></nav>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ClickOutside from 'vue-click-outside';

export default {
  props: {
    // theme: {
    //   type: String,
    //   required: true
    // },
  },
  data() {
    return {
      profile_dropdown: false,
      nav_dropdown: false,
    };
  },
  methods: {
    ...mapActions('authentication', ['logout']),
    ...mapActions('admin', ['changeUser']),
    goRoot() {
      this.$router.replace({ path: '/' });
    },
    logout_redirect() {
      this.logout()
        .then(() => {
          // logout actions in this view
          this.changeUser(null);
        })
        .catch(() => {
          // logout error actions in this view
        })
        .finally(() => this.$router.replace({ path: '/' }));
    },
  },
  computed: {
    ...mapGetters({
      isauthenticated: ['authentication/isauthenticated'],
    }),
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700;800;900&display=swap');

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.2s;
  max-height: 230px;
}
.fadeHeight-enter,
.fadeHeight-leave-to {
  opacity: 0;
  max-height: 0px;
}
</style>
