import Vue from 'vue';
import Router from 'vue-router';

import store from '@/store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    // {
    //   name: 'welcome',
    //   path: '/',
    //   component: () => import('@/views/Welcome'),
    //   meta: {
    //     both: true,
    //   },
    // },
    {
      name: 'terminosycondiciones',
      path: '/terminosycondiciones',
      component: () => import('@/views/terms/Terms'),
      meta: {
        both: true,
      },
    },
    {
      name: 'terms',
      path: '/terms',
      component: () => import('@/views/terms/Terms'),
      meta: {
        both: true,
      },
    },
    {
      name: 'login',
      path: '/',
      component: () => import('@/views/auth/Login'),
      meta: {
        auth: false,
      },
    },
    {
      name: 'restore_password',
      path: '/forgot',
      component: () => import('@/views/auth/RestorePassword'),
      meta: {
        auth: false,
      },
    },
    {
      name: 'restore_password_token',
      path: '/forgot/reset/:token',
      component: () => import('@/views/auth/RestorePasswordToken'),
      meta: {
        auth: false,
      },
    },
    {
      name: 'home',
      path: '/home',
      meta: {
        auth: true,
      },
    },
    /**
     * ########################################  PATIENT ROUTES  ########################################
     */
    {
      name: 'patient',
      path: '/patient',
      component: () => import('@/views/dashboard/user/Patient'),
      meta: {
        auth: true,
      },
      children: [
        {
          path: '/',
          redirect: 'reservations',
        },
        {
          name: 'patient/reservations',
          path: 'reservations',
          component: () =>
            import('@/views/dashboard/user/patient/Reservations'),
          meta: {
            role: 'patient',
          },
        },
        {
          name: 'patient/preconsulta',
          path: 'preconsulta',
          component: () => import('@/views/dashboard/user/patient/Form'),
          meta: {
            role: 'patient',
          },
        },
        {
          name: 'patient/profile',
          path: 'profile',
          component: () => import('@/views/dashboard/user/patient/Profile'),
          meta: {
            role: 'patient',
          },
        },
        {
          name: 'patient/bond',
          path: 'bond/:reservation_id',
          component: () => import('@/views/dashboard/user/patient/Bond'),
          meta: {
            role: 'patient',
          },
        },
      ],
    },
    /**
     * ########################################  DOCTOR ROUTES  ########################################
     */
    {
      name: 'doctor',
      path: '/doctor',
      component: () => import('@/views/dashboard/user/Doctor'),
      meta: {
        auth: true,
      },
      children: [
        {
          path: '/',
          redirect: 'calendar',
        },
        {
          name: 'doctor/calendar',
          path: 'calendar',
          component: () => import('@/views/dashboard/user/doctor/Calendar'),
          meta: {
            role: 'doctor',
          },
        },
        {
          name: 'doctor/patients',
          path: 'patients',
          component: () => import('@/views/dashboard/user/doctor/Patients'),
          meta: {
            role: 'doctor',
          },
        },
        {
          name: 'doctor/payments',
          path: 'payments',
          component: () => import('@/views/dashboard/user/doctor/Payments'),
          meta: {
            role: 'doctor',
          },
        },
        // {
        //   name: 'doctor/settings',
        //   path: 'settings',
        //   component: () => import('@/views/dashboard/user/doctor/Settings'),
        //   meta: {
        //     role: 'doctor',
        //   },
        // },
        {
          name: 'doctor/help',
          path: 'help',
          component: () => import('@/views/dashboard/user/doctor/Help'),
          meta: {
            role: 'doctor',
          },
        },
        {
          name: 'doctor/profile',
          path: 'profile',
          component: () => import('@/views/dashboard/user/doctor/Profile'),
          meta: {
            role: 'doctor',
          },
        },
        {
          name: 'doctor/reservations',
          path: 'reservations/:user_id',
          component: () => import('@/views/dashboard/user/doctor/Reservations'),
          meta: {
            role: 'doctor',
          },
        },
        {
          name: 'doctor/summary',
          path: 'summary',
          component: () => import('@/views/dashboard/user/doctor/Summary'),
          meta: {
            role: 'doctor',
          },
          children: [
            {
              path: '/',
              redirect: '/doctor',
            },
            {
              name: 'doctor/summary/clinic',
              path: 'clinic/:user_id',
              component: () =>
                import('@/views/dashboard/user/doctor/ficha/Ficha'),
              meta: {
                role: 'doctor',
              },
            },
            {
              name: 'doctor/summary/data',
              path: 'data/:user_id',
              component: () =>
                import('@/views/dashboard/user/doctor/ficha/Datos'),
              meta: {
                role: 'doctor',
              },
            },
            {
              name: 'doctor/summary/form',
              path: 'form/:user_id',
              component: () =>
                import('@/views/dashboard/user/doctor/ficha/Form'),
              meta: {
                role: 'doctor',
              },
            },
          ],
        },
      ],
    },
    /**
     * ########################################  PSYCHOLOGIST ROUTES  ########################################
     */
    {
      name: 'psychologist',
      path: '/psychologist',
      component: () => import('@/views/dashboard/user/Psychologist'),
      meta: {
        auth: true,
      },
      children: [
        {
          path: '/',
          redirect: 'calendar',
        },
        {
          name: 'psychologist/calendar',
          path: 'calendar',
          component: () =>
            import('@/views/dashboard/user/psychologist/Calendar'),
          meta: {
            role: 'psychologist',
          },
        },
        {
          name: 'psychologist/patients',
          path: 'patients',
          component: () =>
            import('@/views/dashboard/user/psychologist/Patients'),
          meta: {
            role: 'psychologist',
          },
        },
        {
          name: 'psychologist/payments',
          path: 'payments',
          component: () =>
            import('@/views/dashboard/user/psychologist/Payments'),
          meta: {
            role: 'psychologist',
          },
        },
        // {
        //   name: 'psychologist/settings',
        //   path: 'settings',
        //   component: () =>
        //     import('@/views/dashboard/user/psychologist/Settings'),
        //   meta: {
        //     role: 'psychologist',
        //   },
        // },
        {
          name: 'psychologist/help',
          path: 'help',
          component: () => import('@/views/dashboard/user/psychologist/Help'),
          meta: {
            role: 'psychologist',
          },
        },
        {
          name: 'psychologist/profile',
          path: 'profile',
          component: () =>
            import('@/views/dashboard/user/psychologist/Profile'),
          meta: {
            role: 'psychologist',
          },
        },
        {
          name: 'psychologist/reservations',
          path: 'reservations/:user_id',
          component: () =>
            import('@/views/dashboard/user/psychologist/Reservations'),
          meta: {
            role: 'psychologist',
          },
        },
        {
          name: 'psychologist/summary',
          path: 'summary',
          component: () =>
            import('@/views/dashboard/user/psychologist/Summary'),
          meta: {
            role: 'psychologist',
          },
          children: [
            {
              path: '/',
              redirect: '/psychologist',
            },
            {
              name: 'psychologist/summary/clinic',
              path: 'clinic/:user_id',
              component: () =>
                import('@/views/dashboard/user/psychologist/ficha/Ficha'),
              meta: {
                role: 'psychologist',
              },
            },
            {
              name: 'psychologist/summary/data',
              path: 'data/:user_id',
              component: () =>
                import('@/views/dashboard/user/psychologist/ficha/Datos'),
              meta: {
                role: 'psychologist',
              },
            },
            {
              name: 'psychologist/summary/form',
              path: 'form/:user_id',
              component: () =>
                import('@/views/dashboard/user/psychologist/ficha/Form'),
              meta: {
                role: 'psychologist',
              },
            },
          ],
        },
      ],
    },
    /**
     * ########################################  ADMIN ROUTES  ########################################
     */
    {
      name: 'admin',
      path: '/admin',
      component: () => import('@/views/dashboard/user/Admin'),
      meta: {
        auth: true,
      },
      children: [
        {
          path: '/',
          redirect: 'tables',
        },
        {
          name: 'admin/tables',
          path: 'tables',
          component: () => import('@/views/dashboard/user/admin/Tables'),
          meta: {
            role: 'admin',
          },
        },
        {
          name: 'admin/reservation',
          path: 'reservation/:reservation_id',
          component: () => import('@/views/admin/reservation/Reservation.vue'),
          meta: {
            role: 'admin',
          },
        },
        {
          name: 'admin/profesional',
          path: 'profesional/:user_id',
          component: () => import('@/views/management/Profesional.vue'),
          meta: {
            role: 'admin',
          },
        },
        {
          name: 'admin/selected',
          path: 'selected',
          component: () => import('@/views/dashboard/user/admin/SelectedUser'),
          meta: {
            role: 'admin',
          },
          children: [
            {
              path: '/',
              redirect: 'calendar',
            },
            {
              name: 'admin/selected/calendar',
              path: 'calendar',
              component: () => import('@/views/dashboard/user/admin/Calendar'),
              meta: {
                role: 'admin',
              },
            },
            {
              name: 'admin/selected/payments',
              path: 'payments',
              component: () => import('@/views/dashboard/user/admin/Payments'),
              meta: {
                role: 'admin',
              },
            },
            {
              name: 'admin/selected/settings',
              path: 'settings',
              component: () => import('@/views/dashboard/user/admin/Settings'),
              meta: {
                role: 'admin',
              },
            },
            {
              name: 'admin/selected/profile',
              path: 'profile',
              component: () => import('@/views/dashboard/user/admin/Profile'),
              meta: {
                role: 'admin',
              },
            },
            {
              name: 'admin/selected/help',
              path: 'help',
              component: () => import('@/views/dashboard/user/admin/Help'),
              meta: {
                role: 'admin',
              },
            },
          ],
        },
      ],
    },
    /**
     * ########################################  SUPER DOCTOR ROUTES  ########################################
     */
    {
      name: 'superdoctor',
      path: '/superdoctor',
      component: () => import('@/views/dashboard/user/Superdoctor'),
      meta: {
        auth: true,
      },
      children: [
        {
          path: '/',
          redirect: 'tables',
        },
        {
          name: 'superdoctor/tables',
          path: 'tables',
          component: () => import('@/views/dashboard/user/superdoctor/Tables'),
          meta: {
            role: 'superdoctor',
          },
        },
        {
          name: 'superdoctor/reservation',
          path: 'reservation/:reservation_id',
          component: () => import('@/views/admin/reservation/Reservation.vue'),
          meta: {
            role: 'superdoctor',
          },
        },
        {
          name: 'superdoctor/profesional',
          path: 'profesional/:user_id',
          component: () => import('@/views/management/Profesional.vue'),
          meta: {
            role: 'superdoctor',
          },
        },
        {
          name: 'superdoctor/selected',
          path: 'selected',
          component: () =>
            import('@/views/dashboard/user/superdoctor/SelectedUser'),
          meta: {
            role: 'superdoctor',
          },
          children: [
            {
              path: '/',
              redirect: 'calendar',
            },
            {
              name: 'superdoctor/selected/calendar',
              path: 'calendar',
              component: () =>
                import('@/views/dashboard/user/superdoctor/CalendarSelected'),
              meta: {
                role: 'superdoctor',
              },
            },
            {
              name: 'superdoctor/selected/payments',
              path: 'payments',
              component: () =>
                import('@/views/dashboard/user/superdoctor/Payments'),
              meta: {
                role: 'superdoctor',
              },
            },
            {
              name: 'superdoctor/selected/settings',
              path: 'settings',
              component: () =>
                import('@/views/dashboard/user/superdoctor/Settings'),
              meta: {
                role: 'superdoctor',
              },
            },
            {
              name: 'superdoctor/selected/profile',
              path: 'profile',
              component: () =>
                import('@/views/dashboard/user/superdoctor/Profile'),
              meta: {
                role: 'superdoctor',
              },
            },
            {
              name: 'superdoctor/selected/help',
              path: 'help',
              component: () =>
                import('@/views/dashboard/user/superdoctor/Help'),
              meta: {
                role: 'superdoctor',
              },
            },
          ],
        },
        {
          name: 'superdoctor/doctor',
          path: 'doctor',
          component: () => import('@/views/dashboard/user/superdoctor/Doctor'),
          meta: {
            auth: true,
          },
          children: [
            {
              path: '/',
              redirect: 'calendar',
            },
            {
              name: 'superdoctor/doctor/calendar',
              path: 'calendar',
              component: () =>
                import('@/views/dashboard/user/superdoctor/Calendar'),
              meta: {
                role: 'superdoctor',
              },
            },
            {
              name: 'superdoctor/doctor/patients',
              path: 'patients',
              component: () =>
                import('@/views/dashboard/user/superdoctor/Patients'),
              meta: {
                role: 'superdoctor',
              },
            },
            {
              name: 'superdoctor/doctor/payments',
              path: 'payments',
              component: () => import('@/views/dashboard/user/doctor/Payments'),
              meta: {
                role: 'superdoctor',
              },
            },
            {
              name: 'superdoctor/doctor/settings',
              path: 'settings',
              component: () => import('@/views/dashboard/user/doctor/Settings'),
              meta: {
                role: 'superdoctor',
              },
            },
            {
              name: 'superdoctor/doctor/help',
              path: 'help',
              component: () => import('@/views/dashboard/user/doctor/Help'),
              meta: {
                role: 'superdoctor',
              },
            },
            {
              name: 'superdoctor/doctor/profile',
              path: 'profile',
              component: () => import('@/views/dashboard/user/doctor/Profile'),
              meta: {
                role: 'superdoctor',
              },
            },
            {
              name: 'superdoctor/doctor/reservations',
              path: 'reservations/:user_id',
              component: () =>
                import('@/views/dashboard/user/superdoctor/Reservations'),
              meta: {
                role: 'superdoctor',
              },
            },
            {
              name: 'superdoctor/doctor/summary',
              path: 'summary',
              component: () =>
                import('@/views/dashboard/user/superdoctor/Summary'),
              meta: {
                role: 'superdoctor',
              },
              children: [
                {
                  path: '/',
                  redirect: '/superdoctor/doctor',
                },
                {
                  name: 'superdoctor/doctor/summary/clinic',
                  path: 'clinic/:user_id',
                  component: () =>
                    import('@/views/dashboard/user/superdoctor/ficha/Ficha'),
                  meta: {
                    role: 'superdoctor',
                  },
                },
                {
                  name: 'superdoctor/doctor/summary/data',
                  path: 'data/:user_id',
                  component: () =>
                    import('@/views/dashboard/user/superdoctor/ficha/Datos'),
                  meta: {
                    role: 'superdoctor',
                  },
                },
                {
                  name: 'superdoctor/doctor/summary/form',
                  path: 'form/:user_id',
                  component: () =>
                    import('@/views/dashboard/user/superdoctor/ficha/Form'),
                  meta: {
                    role: 'superdoctor',
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'profile',
      path: '/profile',
      component: () => import('@/views/dashboard/user/patient/Profile'),
      meta: {
        auth: true,
      },
    },
    {
      name: 'reservation',
      path: '/d/:doctor',
      component: () => import('@/views/doctor/Reservation'),
      meta: {
        both: true,
      },
    },
    {
      name: 'purchase_return',
      path: '/purchase_return/:purchase',
      component: () => import('@/views/doctor/Return'),
      meta: {
        auth: true,
      },
    },
    {
      name: 'purchase_fail',
      path: '/purchase_fail/:purchase',
      component: () => import('@/views/doctor/Fail'),
      meta: {
        auth: true,
      },
    },
    // =========================================
    // ERRORS
    // =========================================
    {
      name: '404',
      path: '*',
      component: () => import('@/views/error/404'),
      meta: {
        // tags for router
        both: true,
      },
    },
  ],
});

const hasRole = (role, success, error) => {
  let roles = store.getters['authentication/roles'];
  if (roles.find(e => e.name === role)) {
    success();
  } else {
    error();
  }
};

const redirectHome = (to, from, next) => {
  let roles = store.getters['authentication/roles'];
  if (roles.length !== 0) {
    next({
      path: `/${roles[0].name}/`,
    });
  } else {
    next();
  }
};

const checkRole = (to, from, next) => {
  if (to.meta.role) {
    hasRole(to.meta.role, next, () => {
      next({
        name: 'home',
      });
    });
  } else {
    if (to.name === 'home') {
      redirectHome(to, from, next);
    } else {
      next();
    }
  }
};

const requireAuth = (to, from, next) => {
  // View require authentication -> | (auth user) => next | (not auth user) => redirect to login |
  let token = store.getters['authentication/token'];
  store
    .dispatch('authentication/validate', token)
    .then(() => {
      store.dispatch('loading/change', false);
      checkRole(to, from, next);
    })
    .catch(() => {
      store.dispatch('loading/change', false);
      next({
        name: 'login',
      });
    });
};

const requiresNotToBeAuth = (to, from, next) => {
  // View does not require authentication -> | (auth user) => redirect to home | (not auth user) => next |
  let token = store.getters['authentication/token'];
  store
    .dispatch('authentication/validate', token)
    .then(() => {
      store.dispatch('loading/change', false);
      next({
        name: 'home',
      });
    })
    .catch(() => {
      store.dispatch('loading/change', false);
      next();
    });
};

const doesNotRequireAuth = (to, from, next) => {
  store.dispatch('loading/change', false);
  next();
};

router.beforeEach((to, from, next) => {
  store.dispatch('loading/change', true);
  if (to.matched.some(record => record.meta.both)) {
    doesNotRequireAuth(to, from, next);
  } else {
    if (to.matched.some(record => record.meta.auth)) {
      requireAuth(to, from, next);
    } else {
      requiresNotToBeAuth(to, from, next);
    }
  }
});

export default router;
