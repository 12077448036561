import AuthService from '@/services/authService';

const state = {
  authenticated: false,
  accessToken: localStorage.getItem('accessToken') || '',
  user: localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : {},
};

const getters = {
  token: state => {
    return state.accessToken;
  },
  isauthenticated: state => {
    return state.authenticated;
  },
  user: state => {
    return state.user;
  },
  roles: state => {
    return state.user.roles;
  },
};

const actions = {
  login: ({ commit }, credentials) => {
    commit('CLEAN_LOCAL_STORAGE');
    commit('SET_AUTH_STATUS', false);
    return new Promise((resolve, reject) => {
      AuthService.login(credentials.email, credentials.password)
        .then(response => {
          commit('SET_ACCESS_TOKEN', response.data.token);
          commit('SET_AUTH_STATUS', true);
          commit('SET_USER', response.data.user);
          resolve({
            data: {
              status: 'ok',
              response: response.data,
            },
          });
        })
        .catch(err => reject(err));
    });
  },
  restore: ({ commit }, credentials) => {
    commit('CLEAN_LOCAL_STORAGE');
    commit('SET_AUTH_STATUS', false);
    return new Promise((resolve, reject) => {
      AuthService.restore(credentials.email)
        .then(response => {
          resolve({
            data: {
              status: 'ok',
              response: response.data,
            },
          });
        })
        .catch(err => reject(err));
    });
  },
  restoreToken: ({ commit }, credentials) => {
    commit('CLEAN_LOCAL_STORAGE');
    commit('SET_AUTH_STATUS', false);
    return new Promise((resolve, reject) => {
      AuthService.restoreToken(
        credentials.email,
        credentials.password,
        credentials.password_confirmation,
        credentials.token
      )
        .then(response => {
          resolve({
            data: {
              status: 'ok',
              response: response.data,
            },
          });
        })
        .catch(err => reject(err));
    });
  },
  register: ({ commit }, credentials) => {
    commit('CLEAN_LOCAL_STORAGE');
    commit('SET_AUTH_STATUS', false);
    return new Promise((resolve, reject) => {
      AuthService.register(
        credentials.names,
        credentials.lastnames,
        credentials.rut,
        credentials.phone,
        credentials.email_confirmation,
        credentials.birthdate,
        credentials.region,
        credentials.comuna,
        credentials.address,
        credentials.health,
        credentials.email,
        credentials.password,
        credentials.password_confirmation
      )
        .then(response => {
          commit('SET_ACCESS_TOKEN', response.data.token);
          commit('SET_AUTH_STATUS', true);
          commit('SET_USER', response.data.user);
          resolve({
            data: {
              status: 'ok',
              response: response.data,
            },
          });
        })
        .catch(err => reject(err));
    });
  },
  registerAdmin: ({ commit }, credentials) => {
    commit;
    return new Promise((resolve, reject) => {
      AuthService.register_blank(
        credentials.names,
        credentials.lastnames,
        credentials.email_confirmation,
        credentials.email,
        credentials.password,
        credentials.password_confirmation
      )
        .then(response => {
          resolve({
            data: {
              status: 'ok',
              response: response.data,
            },
          });
        })
        .catch(err => reject(err));
    });
  },
  updateProfile: ({ commit, state }, credentials) => {
    return new Promise((resolve, reject) => {
      AuthService.updateUser(state.user.id, credentials)
        .then(response => {
          commit('SET_USER', response.data.user);
          resolve({
            data: {
              status: 'ok',
              response: response.data,
            },
          });
        })
        .catch(err => reject(err));
    });
  },
  logout: ({ commit }) => {
    return new Promise((resolve, reject) => {
      AuthService.logout()
        .then(() => {
          commit('CLEAN_LOCAL_STORAGE');
          commit('SET_AUTH_STATUS', false);
          resolve({
            data: {
              status: 'ok',
            },
          });
        })
        .catch(err => reject(err));
    });
  },
  validate: ({ commit }, token) => {
    commit('SET_AUTH_STATUS', false);
    commit('SET_USER', {});
    return new Promise((resolve, reject) => {
      if (token) {
        AuthService.validate(token)
          .then(response => {
            console.log('token validated');
            commit('SET_AUTH_STATUS', true);
            commit('SET_USER', response.data.user);
            resolve({
              data: {
                status: 'ok',
                response: response.data,
              },
            });
          })
          .catch(err => {
            commit('SET_ACCESS_TOKEN', '');
            reject(err);
          });
      } else {
        reject({
          data: {
            status: 'error',
            response: {
              message: 'No token',
            },
          },
        });
      }
    });
  },
  updateUser: ({ commit }) => {
    return new Promise((resolve, reject) => {
      AuthService.getUser()
        .then(response => {
          commit('SET_AUTH_STATUS', true);
          commit('SET_USER', response.data.user);
          resolve({
            data: {
              status: 'ok',
              response: response.data,
            },
          });
        })
        .catch(err => {
          commit('SET_ACCESS_TOKEN', '');
          reject(err);
        });
    });
  },
};

const mutations = {
  SET_ACCESS_TOKEN: (state, token) => {
    state.accessToken = token;
    localStorage.setItem('accessToken', token);
  },
  SET_AUTH_STATUS: (state, status) => {
    state.authenticated = status;
  },
  SET_USER: (state, user) => {
    state.user = user;
    localStorage.setItem('user', JSON.stringify(user));
  },
  CLEAN_LOCAL_STORAGE: state => {
    state.accessToken = '';
    localStorage.removeItem('accessToken');
  },
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};
