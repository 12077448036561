import authAxios from '@/utils/authAxios';
import axios from 'axios';

if (process.env.VUE_APP_ENVIROMENT === 'development') {
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  axios.defaults.headers.common['Access-Control-Allow-Methods'] = '*';
}

export default {
  login: (email, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_BACKEND_URL + '/auth/login', {
          email: email,
          password: password,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  restore: email => {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_BACKEND_URL + '/auth/restore', {
          email: email,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  restoreToken: (email, password, password_confirmation, token) => {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_BACKEND_URL + '/auth/restore_token', {
          email: email,
          password: password,
          password_confirmation: password_confirmation,
          token: token,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  register: (
    names,
    lastnames,
    rut,
    phone,
    email_confirmation,
    birthdate,
    region,
    comuna,
    address,
    health,
    email,
    password,
    password_confirmation
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_BACKEND_URL + '/auth/register', {
          names,
          lastnames,
          rut,
          phone,
          email_confirmation,
          birthdate,
          region,
          comuna,
          address,
          health,
          email,
          password,
          password_confirmation,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  validate: token => {
    token;
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/auth/validate', {})
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getUser: () => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/auth')
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  logout: () => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/auth/logout', {})
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  signDoctor: ({
    names,
    lastnames,
    email,
    email_confirmation,
    password,
    password_confirmation,
    bank,
    type,
    number,
    rut,
    phone,
  }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/auth/sign-doctor', {
          names,
          lastnames,
          email,
          email_confirmation,
          password,
          password_confirmation,
          bank,
          type,
          number,
          rut,
          phone,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  signSuperdoctor: ({
    names,
    lastnames,
    email,
    email_confirmation,
    password,
    password_confirmation,
    bank,
    type,
    number,
    rut,
    phone,
  }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/auth/sign-superdoctor', {
          names,
          lastnames,
          email,
          email_confirmation,
          password,
          password_confirmation,
          bank,
          type,
          number,
          rut,
          phone,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  signPsychologist: ({
    names,
    lastnames,
    email,
    email_confirmation,
    password,
    password_confirmation,
    bank,
    type,
    number,
    rut,
    phone,
  }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/auth/sign-psychologist', {
          names,
          lastnames,
          email,
          email_confirmation,
          password,
          password_confirmation,
          bank,
          type,
          number,
          rut,
          phone,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  signAdmin: ({
    names,
    lastnames,
    email,
    email_confirmation,
    password,
    password_confirmation,
  }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/auth/sign-admin', {
          names,
          lastnames,
          email,
          email_confirmation,
          password,
          password_confirmation,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  signPatient: ({
    names,
    lastnames,
    email,
    email_confirmation,
    password,
    password_confirmation,
    birthdate,
    rut,
    phone,
    region,
    comuna,
    address,
    health,
  }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/auth/sign-patient', {
          names,
          lastnames,
          email,
          email_confirmation,
          password,
          password_confirmation,
          birthdate,
          rut,
          phone,
          region,
          comuna,
          address,
          health,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  updateUser: (
    id,
    { names, lastnames, patient, superdoctor, doctor, psychologist, address }
  ) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + `/user/update/${id}`, {
          names,
          lastnames,
          patient,
          superdoctor,
          doctor,
          psychologist,
          address,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // kdfjlkldfjh

  profileAdmin: ({
    id,
    rut,
    phone,
    birthdate,
    region,
    comuna,
    address,
    health,
    names,
    lastnames,
    contact,
    bank,
    type,
    number,
  }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/register_profile_admin', {
          id,
          rut,
          phone,
          birthdate,
          region,
          comuna,
          address,
          health,
          names,
          lastnames,
          contact,
          bank,
          type,
          number,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
