const state = {
  loading: false,
};

const getters = {
  isloading: state => {
    return state.loading;
  },
};

const actions = {
  change: ({ commit }, value) => {
    commit('SET_LOADING', value);
    return value;
  },
};

const mutations = {
  SET_LOADING: (state, value) => {
    state.loading = value;
  },
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};
