<template>
  <div class="main-component bg-gray-100">
    <transition name="fade" mode="out-in">
      <loading-component v-if="loadingStore" />
    </transition>
    <transition name="fade" mode="out-in">
      <div
        v-show="!loadingStore"
        class="full-page flex flex-col justify-between"
      >
        <div>
          <navbar />
          <div
            class="max-w-full w-full mx-auto px-2 sm:px-6 lg:px-8 full-page-container"
          >
            <router-view />
          </div>
        </div>
        <footer class="bg-main w-full">
          <div
            class="max-w-7xl mx-auto py-6 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8"
          >
            <div class="mt-0">
              <p class="text-center text-sm text-gray-400">
                <!-- <img
                  class="h-8"
                  src="@/assets/logo-transparent-green-borders.png"
                  alt=""
                /> -->
              </p>
            </div>
            <div class="mt-8 md:mt-0">
              <p class="text-center text-sm text-gray-200">
                Futura Centro, Todos los derechos Reservados.
              </p>
              <!-- <p class="text-center text-sm text-transparent">
                <a
                  target="_blank"
                  class="mx-1 underline text-green-500 hover:text-green-600 visited:text-green-700 cursor-pointer"
                  href="/terms"
                  >Ver Términos y Condiciones</a
                >
              </p> -->
              <p class="text-center text-sm text-transparent">
                <a
                  class="mx-1 underline text-green-500 hover:text-green-600 visited:text-green-700 cursor-pointer"
                  href="mailto:contacto@futuracentro.cl"
                  >contacto@futuracentro.cl</a
                >
              </p>
            </div>
            <div class="mt-8 md:mt-0 flex flex-col justify-center">
              <p class="text-left md:text-right text-sm  text-transparent">
                <!-- Teléfono: +56 9 75681564 -->
              </p>
              <p class="text-left md:text-right text-sm text-gray-200">
                <!-- <a
                  class="mx-1 underline text-green-500 hover:text-green-600 visited:text-green-700 cursor-pointer"
                  href="mailto:elcorreoquequieres@correo.com"
                  >contacto@futuracentro.cl</a
                > -->
              </p>
            </div>
          </div>
        </footer>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from '@/components/animations/Loading.vue';
import Navbar from '@/components/app/Navbar.vue';

export default {
  name: 'App',
  components: {
    'loading-component': Loading,
    navbar: Navbar,
  },
  computed: {
    ...mapGetters({
      token: ['authentication/token'],
      loadingStore: ['loading/isloading'],
    }),
  },
};
</script>

<style>
html {
  height: 100%;
  margin: 0px;
  background-color: #f8fafc;
  min-height: 100vh;
}

body {
  height: 100%;
  overflow-y: auto;
  overflow-x: initial;
  margin: 0px;
  background-color: #f8fafc;
  min-height: 100vh;
}
.main-component {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  min-height: 100vh;
}
@media (prefers-color-scheme: dark) {
  body {
    background-color: #0f172a !important;
  }
}
.full-page {
  min-height: 100vh;
  width: 100%;
}
.full-page-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.min-main-content {
  min-height: 80vh;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
